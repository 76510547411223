export const CompanyTopsDatas = [
  {
    tag: "会社情報",
    title: "会社紹介",
    subTitle:
      "明治30年創業。加工機のパイオニアとして、現在も世界のモノづくりを牽引しています",
    image:
      "https://docs.google.com/drawings/d/e/2PACX-1vSZt_dOsGlKcw2pcTdJxG0Vk3-XGtXi2WO_fKTiYhvwfNSNoueUBeKaB7r8u7e8OrttLY_8bY6MyHo4/pub?w=924&h=517",
    imageExplanation:
      "サン・サポート・スクエア伊勢工業団地内の本社・工場。<br>標高35ｍの高台にあり、伊勢二見鳥羽ラインが隣接。",
    context:
      "1897（明治30）年、日本初の製材・木工機械メーカーとして伊勢で創業。「切る・削る・磨く」技術の革新をし続け、常に新しい様々な分野の加工機を製造・販売しています。",
    transition: "http://www.kikukawa.co.jp/company/history.html",
    transitionContent: "沿革を詳しく見る",
  },
  {
    tag: "製品紹介",
    title: "製品紹介",
    subTitle:
      "製材機械から鉄道、スマートフォンなど暮らしのあらゆるものに関わっています",
    image:
      "https://docs.google.com/drawings/d/e/2PACX-1vSUMGXf52D0VfHExVe2SdkhaCtG4iC4AQzrwbpUEj7Y6BCmhiyHcaAwx8L_PRqs3TBIpSWeRFYYlwUO/pub?w=400&h=398",
    imageExplanation:
      "現在の製材機械。少ない工程で効率よく加工できるのはKIKUKAWAならではの技術です。",
    context: `
    <h1>【代表的な製品】</h1><br>
    <h1>・木材加工機械　・プラント</h1><br>
    <h1>・電化製品に使用されるプリント配線板の加工機</h1><br>
    <h1>・金属・非鉄金属加工機</h1><br>
    <h1>・自動車関連加工機</h1><br>
    <h1>・鉄道車両関連加工機</h1><br>
    <h1>・航空機関連加工機</h1><br>
    <h1>・フラットパネルディスプレイ関連加工機</h1><br>
    `,
    transition: "http://www.kikukawa.co.jp/company/process.html",
    transitionContent: "製品について詳しく見る",
  },
  {
    tag: "代表取締役",
    title: "代表取締役　菊川 厚",
    subTitle: "<i>KIKUKAWA</i>であなたの力を<br>存分に発揮してください",
    image:
      "https://docs.google.com/drawings/d/e/2PACX-1vR9aiUOaG8RiA3-xcJ_05ZuhiRzI_sAVNeXks466ZYU_lHpxYiDolRJdOrEn4ct0MDzpmdUFagbA_0L/pub?w=457&h=627",
    imageExplanation: "昭和３７年９月２日生 ｜ 平成９年 代表取締役社長就任",
    context:
      "弊社、キクカワエンタープライズは常に変化する時代のニーズに応じた製品づくりを行ってきました。創業時より培った自社の技術を磨いていく精神は、ＩＣＴ・ＡＩなどの最新技術の時代においても、ものづくりの根幹をなす部分として受け継がれています。そのなかで大切にしているのは、社員の皆さんの成長です。2022年は研究棟も新設しました。新入社員の方々には、経験豊富な先輩社員がサポートし、スキルを磨きながら成長できる環境を提供し、研修制度も充実させています。私たちの目標は会社の発展とともに、社員の皆さんも仕事を通じて成長できること。社員一同、皆さんとの出会いを楽しみにしています。",
    transition: "none",
    transitionContent: "none",
  },
  {
    tag: "採用窓口",
    title: "採用窓口",
    subTitle: "<i>KIKUKAWA</i>は<br>あなたの力を求めています！",
    image:
      "https://docs.google.com/drawings/d/e/2PACX-1vQqxRh4wExXY2swRbAAesgB3Rn946kqvjn9RdKQqKJuFJsetzSy9I5yPY_Xn18-Dezh0wejqInv2oQS/pub?w=375&h=402",
    imageExplanation: "総務部 総務課長　小林 知代",
    context: `
    当社の製品は、日本だけではなく世界中で利用されており、社員一人ひとりが、そのことに誇りを持って仕事をしています。<br>
    　<br>
    また、当社では全社方針として「Smile&Happiness」を掲げ、お客様に満足いただけるものづくりと働く人の幸せにより、会社も発展していくと考えています。会社を支えるのは人です。当社では働く環境の改善や人材育成にも力を入れていきたいと思っています。<br>
    　<br>
    ものづくりに興味がある方、人と話をするのが好きな方、ぜひエントリーしてください。皆様のご応募を心よりお待ちしております！<br>
    `,
    transition: "none",
    transitionContent: "none",
  },
];
