export const CompanyDetailsDatas = {
  copyRight:
    "Copyright (C) 2023 KIKUKAWA ENTERPRISE, INC. All Rights Reserved.",
  jobType: [
    {
      content: `
      <h1>開発設計部門</h1><br>
      　<br>
      <h1>■機械設計</h1><br>
      顧客との打ち合わせ／工作機械・プラント等の３ＤＣＡＤを用いた設計・製図／新たな加工方法や機構などの研究・開発<br>
      
      <h1>■電気設計</h1><br>
      製品を作る上での機能における部品選定／ＣＡＤを用いた電気制御回路の設計・製図／カメラ画像や無線技術を用いた検査・製造技術開発<br>
      
      <h1>■制御ソフトウェア開発</h1><br>
      様々な素材を加工する機械のソフトウェア開発<br>
      `,
    },
    {
      content: `
      <h1>営業部門</h1><br>
      <h1>■営業職（国内営業）</h1><br>
      主として既存顧客の営業・顧客との打ち合わせ・展示会での営業<br>
      
      ※勤務地は本社以外（東京・大阪・名古屋・福岡）のいずれかとなります。<br>
      　<br>
      `,
    },
    {
      content: `
      <h1>資材調達部門</h1><br>
      　<br>
      資材等の発注／納期・仕入・伝票の管理／購買データ管理／棚卸管理／集計業務ほか<br>
      　<br>
      `,
    },
    {
      content: `
      <h1>製造部門</h1><br>
      　<br>
      <h1>■機械オペレーター</h1><br>
      金属部品の機械加工<br>
      
      <h1>■機械組立・サービスエンジニア</h1><br>
      工作機械等の組み立て／機械の据え付け／アフターサービスほか<br>
      `,
    },
  ],
  requirements: [
    {
      content: `
      <h1>募集対象</h1><br>

      ・理系　大学院生／学部生<br>
      ・文系　大学院生／学部生<br>
      ・高等専門学校生<br>
      ・専門学校生<br>

      <h1>募集人数</h1><br>
      1～5名<br>

      <h1>募集内訳</h1><br>

      決定次第、ご案内します。
      `,
    },
    {
      content: `
      <h1>希望する人材</h1><br>

      <h1>■ICT／IoT、AIを活用した機械の開発</h1><br>
      ・ ICT／IoT、AIに興味がある<br>
      ・ プログラム作りに興味がある<br>
      ・ C#、Python、Ruby、組込みシステム等の開発環境でソフトを作成したことがある<br>
      ・制御設計（PLCプログラミング）の経験のある方<br>

      <h1>■国内のお客様への営業業務</h1><br>
      ・ 人と話をするのが好きな方<br>
      ・ いろんな場所に行ってみたい<br>

      ほかにも、機械・電気の勉強をしてきた方や興味がある方、数学や物理が好きな方、ものづくりが好きな方など<br>
      `,
    },
  ],
  flow: [
    {
      content: `
      エントリー<br>
      　　▼<br>
      説明会<br>
      (対面・WEB）<br>
      　　▼<br>
      エントリーシート提出<br>
      （随時）<br>
      　　▼<br>
      書類選考<br>
      　　▼<br>
      筆記試験・役員面接<br>
      　　▼<br>
      内々定<br>

      ※選考で筆記・適性試験があります。<br>
      ※エントリーから内々定まで1か月以内<br>
      ※グループワーク・ディスカッション・<br>
      グループ面接はありません<br>
      `,
    },
    {
      content: `
      <h1>提出書類</h1><br>

      □　履歴書（写真つき）<br>
      □　成績証明書<br>
      □　卒業見込証明書<br>
      `,
    },
    {
      content: `
        <h1>説明会・選考での交通費等について</h1><br>

        公共交通機関は当社が負担いたします。<br>
        遠方の場合、制限はありますが、ご相談ください。<br>
      `,
    },
  ],
  welfare: [
    {
      content: `
        <h1>給与</h1><br>

        <h1>(1)【正社員】営業職（国内）</h1><br>
        高専 卒業見込みの方<br>
        月給：256,812円（一律手当含む）<br>

        大学 卒業見込みの方<br>
        月給：279,348円（一律手当含む）<br>

        大学院 卒業見込みの方<br>
        月給：287,902円（一律手当含む)<br>

        <h1>(2)【正社員】営業職以外 </h1><br>
        高専 卒業見込みの方<br>
        月給：183,912円<br>

        大学 卒業見込みの方<br>
        月給：206,448円<br>

        大学院 卒業見込みの方<br>
        月給：215,002円<br>
      `,
    },
    {
      content: `
        <h1>手当（月額）</h1><br>

        <h1>(1)【正社員】営業職（国内）</h1><br>
        　通勤手当：1,000円～50,000円<br>
        　扶養手当：7,000円以上<br>
        　一律勤務地手当：40,000円～50,000円（固定給に含む）<br>
        　一律営業職手当：32,900円（固定給に含む）<br>
        　営業所成果手当<br>
        　役付手当<br>
        　資格手当 ほか<br>

        <h1>(2)【正社員】営業職以外</h1><br>

        　通勤手当：1,000円～50,000円<br>
        　扶養手当：7,000円以上<br>
        　役付手当<br>
        　資格手当 ほか<br>

        <h1>備考：〔一律勤務地手当〕</h1><br>

        東京 ： 50,000円<br>
        名古屋・大阪・福岡 ： 40,000円<br>

      `,
    },
    {
      content: `
        <h1>昇給・賞与</h1><br>

        <h1>昇給</h1><br>
        　年1回<br>

        <h1>賞与</h1><br>
        　年2回<br>

        　備考：業績によっては決算賞与あり<br>
      `,
    },
    {
      content: `
        <h1>休日・休暇</h1><br>

        完全週休2日制 <br>
        （基本は土曜・日曜・祝日、ほか会社指定日）<br>

        ※年１回土曜出勤あり、祝日の出勤日あり<br>
        ＧＷ休暇、夏季休暇、年末年始休暇など<br>
        　<br>
        <h1>休暇制度</h1><br>
        年次有給休暇※、慶弔休暇、産前産後休暇、育児休業、介護休暇、子の看護休暇、出生時育児休業（産後パパ育休）<br>
        ※入社2ヶ月後…6日、入社半年後...4日
      `,
    },
    {
      content: `
        <h1>福利厚生</h1><br>
        社会保険完備（健康保険、厚生年金、雇用保険、労災保険）<br>
        退職時株式給付制度（J-ESOP）<br>
        慶弔見舞金<br>
        昼食費補助<br>
        予防接種費用補助<br>
      `,
    },
    {
      content: `
        <h1>試用期間</h1><br>
        2ヶ月 ※労働条件の変更なし<br>
      `,
    },
    {
      content: `
        <h1>その他</h1><br>

        労働組合<br>
        （JAMキクカワエンタープライズ労働組合）<br>

        ※労働組合も各種レクリエーションを企画・実施しています。<br>
      `,
    },
  ],
  internship: [
    {
      content: `
      <h1>（対面式）１day仕事体験</h1><br>
      　<br>
      ・11月以降、随時開催予定<br>
      ・会社概要説明のほか、研修は実際の職場環境で行います。主に若手スタッフが指導を担当しますので、仕事だけでなくリアルな話も聞くことができます。今後の進路を選択する際の参考にしていただける内容となっています。<br>
      　<br>
      〔開発設計職〕<br>
      ３Ｄ-ＣＡＤを用いた機械設計またはＣＡＤを用いた電気回路設計のいずれかを選択いただきます。<br>
      〔営業職（国内）〕<br>
      営業職を１日体験していただきます。<br>
      　<br>
      【実施場所】<br>
      開発設計職：本社（三重県伊勢市）<br>
      営業職：本社または営業所<br>
      ※内容は予定ですので、変更の可能性があります。<br>
      `,
    },
  ],
  session: [
    {
      content: `
        <h1>WEB開催</h1><br>
        会社概要の説明と、会社紹介動画をご覧いただきます。少しでも弊社の雰囲気、働いている人の表情、製造している製品の大きさなどを感じてください。最後に質疑応答もございます。<br>
        　<br>
        随時開催<br>
        ・10:00 ~ 10:30<br>
        ・15:00 ~ 15:30<br>
        ※上記以外の時間帯も可能です。ご相談ください。
      `,
    },
    {
      content: `
        <h1>本社開催（対面）</h1><br>
        随時開催<br>
        ・10:00 ~ 12:00<br>
        ※上記以外の時間帯も可能です。ご相談ください。<br>
        　<br>
        【場所】<br>
         本社・工場 （三重県伊勢市朝熊町3477-36）<br>
          MAPは<a href="https://maps.app.goo.gl/SECVoTFK85gpHY36A">こちら</a> <br>
          　<br>
          【アクセス方法】 <br>
          ・公共交通機関をご利用の場合 / 近鉄 「五十鈴川駅」下車<br>
            ※当社にて、駅との送迎を行います。<br>
            
            ・お車をご利用の場合<br>
             伊勢二見鳥羽ライン「朝熊東ＩＣ」より 車で２分<br>
             　<br>
             【交通費】<br>
              公共交通機関は当社が負担いたします。<br>
              遠方の場合、制限はありますが、 ご相談ください。<br>
              　<br>
               【持ち物】<br>
                筆記用具<br>
                　<br>
      `,
    },
  ],
};
