import { Text } from "@chakra-ui/react";

type Props = {
  content: string;
};

const CopyRight: React.FC<Props> = ({ content }) => {
  return (
    <Text fontSize={"xs"} textAlign={"center"}>
      {content}
    </Text>
  );
};

export default CopyRight;
