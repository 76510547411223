import React, { ReactNode } from "react";
import { Text, Link } from "@chakra-ui/react";

interface Props {
  inputStr: string;
  center?: boolean;
  fontWeight?: number;
  textColor?: string;
  fontSize?: string;
  textSpace?: number;
}

const RenderTextWithBreaks: React.FC<Props> = ({
  inputStr,
  center = false,
  fontWeight = 500,
  textColor,
  fontSize = "xl",
  textSpace = 0,
}) => {
  const splittedTexts = inputStr.split("<br>");

  const linkRegex = /<a\s+[^>]*href=["']([^"']*)["'][^>]*>(.*?)<\/a>/gi;

  const parseText = (str: string): ReactNode[] => {
    const parts: ReactNode[] = [];
    let lastIndex = 0;

    str.replace(linkRegex, (match, href, content, index) => {
      const beforeLinkText = str.slice(lastIndex, index);
      if (beforeLinkText) {
        parts.push(beforeLinkText);
      }
      parts.push(
        <Link href={href} key={href}>
          {content}
        </Link>
      );
      lastIndex = index + match.length;
      return match; // Needed for TypeScript but isn't used in our case.
    });

    // const afterLinkText = str.slice(lastIndex);
    // if (afterLinkText) {
    //   parts.push(afterLinkText);
    // }
    

    str.replace(/<i>(.*?)<\/i>/g, (match, content, index) => {
      const beforeItalicText = str.slice(lastIndex, index);
      if (beforeItalicText) {
        parts.push(beforeItalicText);
      }
      parts.push(
        <i key={index}>
          {content}
        </i>
      );
      lastIndex = index + match.length;
      return match;
    });
  
    const remainingText = str.slice(lastIndex);
    if (remainingText) {
      parts.push(remainingText);
    }

    return parts;
  };

  return (
    <>
      {splittedTexts.map((text, index) => {
        // h1で囲われているかどうかで太文字にするか決める
        const isBold = /<h1>.*<\/h1>/.test(text);
        const dynamicFontWeight = isBold ? "bold" : fontWeight;
        const cleanedText = text.replace(/<\/?h1>/g, "");

        return (
          <Text
            key={index}
            m={textSpace}
            fontWeight={dynamicFontWeight}
            fontSize={fontSize}
            textAlign={center ? "center" : undefined}
            textColor={textColor}
          >
            {parseText(cleanedText)}
          </Text>
        );
      })}
    </>
  );
};

export default RenderTextWithBreaks;
