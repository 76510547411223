import { Card, CardBody, Stack, Text } from "@chakra-ui/react";
import EmployeeCard from "./parts/EmployeeCard";
import { EmployeesData } from "../../data/Employees";

const EmployeesList = () => {
  return (
    <>
      <Card maxW="sm">
        <Text
          textAlign={"center"}
          mt={8}
          mb={-4}
          fontSize={"xl"}
          fontWeight={700}
        >
          社員インタビュー
        </Text>
        <CardBody>
          <Stack mt="6" spacing="3">
            {EmployeesData.map((data, index) => (
              <EmployeeCard
                key={index}
                id={index}
                image={data.image}
                department={data.department}
                name={data.name}
                career={data.career}
              />
            ))}
          </Stack>
        </CardBody>
      </Card>
    </>
  );
};

export default EmployeesList;
