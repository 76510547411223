import { Box, Link } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

const Back = () => {
  const navigate = useNavigate();

  return (
    <Box w="100%" textAlign="right" mb={4} pr={4}>
      <ArrowForwardIcon boxSize={"24px"} mt={-1.5} />
      <Link onClick={() => navigate(-1)} fontSize={"xl"}>
        戻る
      </Link>
    </Box>
  );
};

export default Back;
