import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { CompanyTopsDatas } from "../../../data/CompanyTops";
import SmoothScrollLink from "../../common/SmoothScrollLink";

function HamburgerMenu() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      {/* メニューボタン */}
      <Button variant="outline" onClick={onOpen}>
        <HamburgerIcon />
      </Button>

      {/* ドロワーメニュー */}
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>メニュー</DrawerHeader>

            <DrawerBody>
              {/* ここにメニューの内容を追加 */}
              {CompanyTopsDatas.map((data, index) => (
                <Box mb={4}>
                  <SmoothScrollLink href={"#" + data.tag} offset={128}>
                    {data.tag}
                  </SmoothScrollLink>
                </Box>
              ))}
              <Box mb={4}>
                <SmoothScrollLink href={"#社員インタビュー"} offset={128}>
                  社員インタビュー
                </SmoothScrollLink>
              </Box>
              <Box mb={4}>
                <SmoothScrollLink href={"#採用情報"} offset={128}>
                  採用情報
                </SmoothScrollLink>
              </Box>
            </DrawerBody>

            <DrawerFooter>
              {/* 必要に応じてフッター項目を追加 */}
              <Button variant="outline" mr={3} onClick={onClose}>
                キャンセル
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Box>
  );
}

export default HamburgerMenu;
