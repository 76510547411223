import React from "react";

type SmoothScrollLinkProps = {
  href: string;
  offset?: number; // ずらすピクセル数
  className?: string;
  children?: React.ReactNode;
};

const SmoothScrollLink: React.FC<SmoothScrollLinkProps> = ({
  href,
  offset = 0,
  className,
  children,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    const targetElement = document.querySelector(href);
    if (targetElement) {
      const position =
        targetElement.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({ top: position, behavior: "smooth" });
    }
  };

  return (
    <a href={href} onClick={handleClick} className={className}>
      {children}
    </a>
  );
};

export default SmoothScrollLink;
