import { Center, Stack, Box } from "@chakra-ui/react";
import EmployeesList from "../components/topPage/EnployeesList";
import { CompanyDetailsDatas } from "../data/CompanyDetails";
import { CompanyTopsDatas } from "../data/CompanyTops";

import TitleCard from "../components/topPage/parts/TitleCard";
import FooterMenu from "../components/topPage/footer/FooterMenu";
import Header from "../components/topPage/header/Header";
import CopyRight from "../components/common/copyRight";
import ScrollToTop from "../components/common/ScrollToTop";

const Home = (): React.ReactElement => {
  return (
    <Box mb={"96px"}>
      <Header
        name="キクカワエンタープライズ株式会社"
        message="<i>KIKUKAWA</i>で見つけよう<br>わたしのミライ。"
        subMessage="あなたの情熱、私たちと共有しませんか？"
      ></Header>
      <Center>
        <Stack h="100%">
          <Box backgroundColor={"#BEFFFB"} px={8} py={12}>
            <Stack h="100%" spacing={8}>
              {CompanyTopsDatas.map((data, index) => (
                <div id={data.tag}>
                  <TitleCard
                    title={data.title}
                    subTitle={data.subTitle}
                    context={data.context}
                    image={data.image}
                    imageExplanation={data.imageExplanation}
                    transition={data.transition}
                    transitionContent={data.transitionContent}
                  />
                </div>
              ))}
              <div id="社員インタビュー">
                <EmployeesList />
              </div>
            </Stack>
          </Box>
          <Box px={8}>
            <div id="採用情報">
              <FooterMenu />
            </div>
          </Box>
          <ScrollToTop></ScrollToTop>
          <CopyRight content={CompanyDetailsDatas.copyRight} />
        </Stack>
      </Center>
    </Box>
  );
};

export default Home;
