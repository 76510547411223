import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";

import { CompanyDetailsDatas } from "../data/CompanyDetails";
import DetailCard from "../components/detailsPage/Card";
import { Center, VStack, Box, Text } from "@chakra-ui/react";
import Question from "../components/detailsPage/question";
import Back from "../components/common/Back";

const Detail = () => {
  const { kind } = useParams<{ kind: any }>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<boolean>(false);
  //const [_kind, setKind] = useState<string>("jobType");
  const [title, setTitle] = useState<string>("none");
  const [_companyDetailData, setCompanyDetailData] = useState([
    { content: "none" },
  ]);

  type DetailKind =
    | "jobType"
    | "requirements"
    | "flow"
    | "welfare"
    | "internship"
    | "session";

  const isDetailKind = (value: string): value is DetailKind => {
    return [
      "jobType",
      "requirements",
      "flow",
      "welfare",
      "internship",
      "session",
    ].includes(value);
  };

  const changeTitle = (value: string): string => {
    switch (value) {
      case "jobType":
        return "募集職種";
      case "requirements":
        return "募集要項";
      case "flow":
        return "採用フロー";
      case "welfare":
        return "待遇・福利厚生について";
      case "internship":
        return "インターンシップ情報";
      case "session":
        return "説明会情報";
      case "question":
        return "よくある質問";
      default:
        return "Error";
    }
  };

  useEffect(() => {
    if (kind) {
      try {
        //setKind(kind);
        setLoading(false);
        if (isDetailKind(kind)) {
          setCompanyDetailData(CompanyDetailsDatas[kind]);
          setTitle(changeTitle(kind));
        } else {
          if (kind === "question") setTitle(changeTitle(kind));
          console.log("none");
        }
      } catch (e) {
        setError(true);
      }
    }
  }, [kind]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error</div>;
  }

  if (kind === "question") {
    return (
      <Box
        bg="#BEFFFB"
        w="100vw"
        h="100vh"
        maxW="512px"
        mx="auto"
        overflow={"auto"}
      >
        <Center>
          <Box w="90vw" maxW="480px">
            <Text
              textAlign={"center"}
              fontWeight={700}
              fontSize={"2xl"}
              mt={"64px"}
              mb={"16px"}
            >
              【{title}】
            </Text>
            <Question />
          </Box>
        </Center>
        <Back></Back>
      </Box>
    );
  }

  return (
    <>
      <Center>
        <VStack>
          <Box bg="#BEFFFB" w="100vw" h="100vh" maxW="512px" overflow={"auto"}>
            <Center>
              <Box w="90vw" maxW="480px">
                <Text
                  textAlign={"center"}
                  fontWeight={700}
                  fontSize={"2xl"}
                  mt={"64px"}
                  mb={"16px"}
                >
                  【{title}】
                </Text>
                {_companyDetailData.map((data, index) => (
                  <Box mb={"32px"}>
                    <DetailCard key={index} content={data.content} />
                  </Box>
                ))}
              </Box>
            </Center>
            <Back></Back>
          </Box>
        </VStack>
      </Center>
    </>
  );
};

export default Detail;
