import { HStack, Image, Text } from "@chakra-ui/react";

type Props = {
  image?: string;
  text?: string;
};

const MenuBar: React.FC<Props> = ({ image, text }) => {
  return (
    <HStack spacing={4} mb={"8px"}>
      <Image objectFit={"cover"} src={image} alt="icon" boxSize="50px" />
      <Text fontSize={"xl"} fontWeight={600}>
        {text}
      </Text>
    </HStack>
  );
};

export default MenuBar;
