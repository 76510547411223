import { Card, Stack, CardBody, Box } from "@chakra-ui/react";

import RenderTextWithBreaks from "../../utils/indention";

type Props = {
  title?: string;
  content?: string;
};

const DetailCard: React.FC<Props> = ({ content }) => {
  return (
    <Card>
      <CardBody>
        <Stack spacing="4">
          <Box>
            <RenderTextWithBreaks inputStr={content ?? "none"} fontSize="md" />
          </Box>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default DetailCard;
