import {
  Card,
  CardBody,
  Image,
  Stack,
  Text,
  Box,
  Center,
} from "@chakra-ui/react";

import RenderTextWithBreaks from "../../../utils/indention";
import { useNavigate } from "react-router-dom";

type Props = {
  image?: string;
  department?: string;
  name?: string;
  career?: string;
  color?: string;
  id: number;
};

const EmployeeCard: React.FC<Props> = ({
  image,
  department,
  name,
  career,
  color,
  id,
}) => {
  const navigate = useNavigate();
  const navigation = () => {
    navigate(("/about/" + id) as any as string);
  };

  return (
    <Box onClick={navigation}
      _hover={{ cursor: "pointer" }}
    >
      <Card maxW="sm" backgroundColor={color ?? "#FFDCB4"} borderRadius={0}>
        <CardBody>
          <Stack mt="6" spacing="3">
            <Center h="100%"></Center>
            <Image
              src={
                image ??
                "https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
              }
              alt="Green double couch with wooden legs"
              mb={-3}
              h="300px"
              objectFit="cover"
            />
            <Box h="3rem" backgroundColor={color ?? "#FE9821"}>
              <Center h="100%">
                <Text as="b" fontSize="xl" textColor="white">
                  {department}
                </Text>
              </Center>
            </Box>
            <Text ml={6} fontSize="xl" fontWeight={700}>
              {name}
            </Text>
            <Box ml="6">
              <RenderTextWithBreaks inputStr={career ?? "none"} />
            </Box>
          </Stack>
        </CardBody>
      </Card>
    </Box>
  );
};

export default EmployeeCard;
