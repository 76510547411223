import React from "react";
import { createRoot } from "react-dom/client";

import "./index.css";
import { AppRoutes } from "./routes/index";
import reportWebVitals from "./reportWebVitals";

// React 18でReactDOM.renderはサポート外。createRootを使用する。
const container = document.getElementById("root");

if (container) {
  createRoot(container).render(
    <React.StrictMode>
      <AppRoutes></AppRoutes>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
