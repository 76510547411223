type Props = {
  direction?: string;
  content?: string;
};

const SpeechBubble: React.FC<Props> = ({
  direction = "left",
  content = "none",
}) => {
  switch (direction) {
    case "left":
      return (
        <div className="relative bg-white p-6 rounded-lg shadow-md w-64">
          <p>{content}</p>

          <div className="absolute top-1/4 left-0 w-0 h-0 -ml- transform -translate-y-1/2">
            <div className="w-5 h-5 bg-white transform rotate-45"></div>
          </div>
        </div>
      );
    case "right":
      return (
        <div className="relative bg-gray-300 p-6 rounded-lg shadow-md w-64">
          <p>{content}</p>

          <div className="absolute top-1/4 right-0 w-0 h-0 mr-3 transform -translate-y-1/2">
            <div className="w-5 h-5 bg-gray-300 transform rotate-45"></div>
          </div>
        </div>
      );
    default:
      return (
        <div className="relative bg-white p-6 rounded-lg shadow-md w-64">
          <p>{content}</p>

          <div className="absolute top-1/4 right-0 w-0 h-0 mr-3 transform -translate-y-1/2">
            <div className="w-5 h-5 bg-white transform rotate-45"></div>
          </div>
        </div>
      );
  }
};

export default SpeechBubble;
