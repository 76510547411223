import InterviewTop from "../components/interviewPage/top";
import InterviewContent from "../components/interviewPage/content";
import SubInterviewContent from "../components/interviewPage/subContent";

import { EmployeesData } from "../data/Employees";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Center, Flex, AspectRatio } from "@chakra-ui/react";
import Back from "../components/common/Back";

const Interview: React.FC = () => {
  const { id } = useParams<{ id: any }>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<boolean>(false);
  const [_id, setId] = useState<number>(0);

  useEffect(() => {
    if (id) {
      try {
        setId(parseInt(id, 10));
        setLoading(false);
      } catch (e) {
        setError(true);
      }
    }
  }, [id]);

  const employee = EmployeesData[_id];
  const employeeInterview = employee.interviewData;

  var name: string = "";

  if (employee.ruby === "") {
    name = employee.name;
  } else {
    name = employee.name + "（" + employee.ruby + "）";
  }

  var interviewData = EmployeesData[_id].interviewData?.interviews;
  if (interviewData === undefined) {
    interviewData = [];
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error</div>;
  }

  return (
    <>
      <Box mt={"64px"} mb={"96px"}>
        <InterviewTop
          title={employeeInterview?.department}
          content={employeeInterview?.content}
          image={employeeInterview?.interviewImage}
          name={name}
          department={employee.department}
          career={employee.career}
        />
        {interviewData.map((data, index) => (
          <Box mt={"32px"} mb={"32px"}>
            <InterviewContent
              key={index}
              title={data.title}
              content={data.content}
            />
          </Box>
        ))}
        <SubInterviewContent
          title="ある1日のスケジュール"
          content={employeeInterview?.schedule}
          image={employeeInterview?.subInterviewImage}
        />
        <SubInterviewContent
          title="キャリアパス"
          content={employeeInterview?.careerPath}
        />
        <SubInterviewContent
          title="休日の過ごし方"
          content={employeeInterview?.holiday}
        />
        {employee.video ? (
          <>
            <Center>
              <Box w="80vw" maxW={"512px"}>
                <AspectRatio maxW={"512px"}>
                  <iframe
                    src={employee.video ?? "none"}
                    allowFullScreen
                    title={employee.video}
                    style={{ display: "block", margin: "auto" }}
                  ></iframe>
                </AspectRatio>
              </Box>
            </Center>
          </>
        ) : (
          <></>
        )}
        <Center w="100%">
          <Flex maxW="512px" justifyContent="flex-end" w="100%">
            <Box>
              <Back></Back>
            </Box>
          </Flex>
        </Center>
      </Box>
    </>
  );
};

export default Interview;
