export const EmployeesData = [
  {
    video: "https://www.youtube.com/embed/V3s7E9Z36XM?si=kIPbp789gP9y_C6U",
    image:
      "https://docs.google.com/drawings/d/e/2PACX-1vQoKD7u7MLxxjCt9H_JWg6nEIkCKy2mUbHLv6FgaQaYbo86BX0URr7dpjeLYTe1LA_dxtyuVa-k4mkt/pub?w=464&h=518",
    department: "開発設計部　電気設計",
    name: "T.N.",
    ruby: "",
    career: "2016年入社<br>鳥羽商船高等専門学校<br>電子機械工学科 卒業",
    interviewData: {
      interviewImage:
        "https://docs.google.com/drawings/d/e/2PACX-1vRp_K0CXEDtcITuK5elr8zp3HOVa_pKwCof4k7rXOINvtXcQPjJxAt4ytPV2MA1p98ZzHGfncCmO_EE/pub?w=683&h=498",
      subInterviewImage:
        "https://docs.google.com/drawings/d/e/2PACX-1vTJaWYUcya5npf_DcX-SfiBcv4IXdE3SQ0MM91Ta3q0pQk7pYIEZ1uQzdrnfPKz66tT-5Q8lH_2rC5l/pub?w=926&h=615",
      department: "開発設計部",
      content: "自分のプログラムで実際に機械が動いたときが、何より嬉しい瞬間",
      schedule: `
        06:00　起床<br>
        08:00　出社<br>
        08:10　朝礼<br>
        08:20　設計図･プログラムの作成<br>
        12:00　昼食<br>
        12:45　設計図･プログラムの作成<br>
        16:45　作業終了<br>
        17:00　退勤<br>
        18:30　夕食<br>
        19:00　友人と遊びに行ったり、自宅でのんびりしたりしています<br>
        23:00　就寝<br>
      `,
      careerPath: `
        2016年　入社　製造部に配属<br>
        2019年　開発設計部に異動<br>
      `,
      holiday: `
        一人暮らしのため、休日は料理やスイーツ作りをしています。グラタン、シチューやカレー、ケーキも作ります。<br>
        ほかには、山登りや他県への観光にもよく行きます。月に一度は伊勢の朝熊山に登りますし、寺巡りが趣味で、よく京都に行っています。<br>
      `,
      interviews: [
        {
          title: "入社へのきっかけ、決め手は？",
          content:
            "学校での会社説明会がきっかけでした。実家は伊勢ではないのですが、学生時代によく出かけていた伊勢の街が気に入り、ここで働きたいと思い仕事を探していました。最終的には会社見学で「いい雰囲気の会社だな」と思ったのが決め手です。",
        },
        {
          title: "仕事のやりがいを感じるとき",
          content:
            "やはり自分のプログラムで実際に機械が動いた瞬間は嬉しいです。<br>「あ、動いたなあ！」と。",
        },
        {
          title: "日々、意識していること",
          content:
            "実際に動作チェックなどで機械を動かす時に、機械内部や近辺に人がいないか気を付けながら作業しています。当社の機械は大型のものが多く、安全面に配慮が必要ですので、声を掛けながら作業をしています。",
        },
        {
          title: "社員の矜持として",
          content:
            "自分に出来る限りの事をして、会社の役に立てるように今後も努めていきたいと思っています。",
        },
        {
          title: "KIKUKAWAの良いところ",
          content:
            "皆さん人当たりが良く、分からないことがあればすぐにアドバイスをもらえ、時にはフォローをしてくれます。",
        },
        {
          title: "就職活動中の皆さんへ",
          content:
            "いろいろな企業を見た上で自分に合った企業を見つけていただきたいです。頑張ってください！",
        },
      ],
    },
  },
  {
    image:
      "https://docs.google.com/drawings/d/e/2PACX-1vQ5f-6yWOYQTuhhjugxUra6msIZ7AuoJL02gtXbaMsJLp7MvJAOzAwENNvYabXVgSSU_vJRMMsuWhHO/pub?w=668&h=475",
    department: "開発設計部　電気設計",
    name: "Y.W.",
    ruby: "",
    career: "2021年入社<br>日本大学 生産工学部<br>数理情報工学科 卒業",
    interviewData: {
      interviewImage:
        "https://docs.google.com/drawings/d/e/2PACX-1vQ5f-6yWOYQTuhhjugxUra6msIZ7AuoJL02gtXbaMsJLp7MvJAOzAwENNvYabXVgSSU_vJRMMsuWhHO/pub?w=668&h=475",
      subInterviewImage:
        "https://docs.google.com/drawings/d/e/2PACX-1vQbtRUy2uzjMhayOLIe_DNvAIaPehlYr9mOxxGCU-mpNI5IfTAPB3sDIzYNcI2R7f-KdC6qEgO5RrY6/pub?w=726&h=486",
      department: "開発設計部",
      content: "なかなかやる気が起きないときも、まずは行動すること",
      schedule: `
      07:55　出社<br>
      08:10　朝礼<br>
      08:20　プログラミング<br>
      12:00　昼食・コーヒー<br>
      12:45　プログラミング再開<br>
      16:45　退勤<br>
      23:00　就寝<br>
      `,
      careerPath: `
      2021年　入社　製造部に配属<br>
      2023年　開発設計部に異動<br>
      `,
      holiday: `
      外では登山、家では映像鑑賞や絵を描くなどしています。ただやはり、友人と外出するのが一番リフレッシュできます。<br>
      時勢的にも インドア、アウトドア両方を楽しむことがいいと思っています。<br>
      `,
      interviews: [
        {
          title: "入社へのきっかけ、決め手は？",
          content:
            "本社が実家から通える距離にあることを重視していました。その上で、会社を訪問した際の雰囲気が良かったことや、 会社のHPがある程度整っていたこと等が決め手となりました。",
        },
        {
          title: "仕事のやりがいを感じるとき",
          content:
            "経験を積んでできることが増えた時や、視野や配慮ができる幅の広がりを感じられた時です。自身の成長を実感することでやりがいを感じているのかもしれません。",
        },
        {
          title: "日々、意識していること",
          content:
            "「能動的に過ごすこと」といっても、とにかくやる気と言いたいわけではなく、やる気が起きなくても「まずは行動する」ということを意識しています。そうすると、いつの間にか自分の頭で色々考えて、先輩に質問をする時なども内容の濃い話ができるようになっています。",
        },
        {
          title: "社員の矜持として",
          content:
            "「井の中の蛙」という考えを忘れず、常に視野を広く持ちたいと思います。",
        },
        {
          title: "今後の目標",
          content:
            "当社は多様な機械を製造しているので、担当する機械のバリエーションやパターンを増やしていくことが目標です。",
        },
        {
          title: "就職活動中の皆さんへ",
          content:
            "よく就活のアドバイスで「コミュニケーション能力が大事」というのを聞くと思いますが、本当に大事です。",
        },
      ],
    },
  },
  {
    image:
      "https://docs.google.com/drawings/d/e/2PACX-1vTTcTMAjrCtBvmOeHCbRPUxyEisQxQ6l3YhWs1J6IGCBNTjG6ZMZgota-5nbzMJsT5l-h3IPQIMlJBa/pub?w=469&h=484",
    department: "開発設計部　機械設計",
    name: "Y.W.",
    ruby: "",
    career: "2017年入社<br>日本大学 工学部<br>機械工学科 卒業",
    interviewData: {
      interviewImage:
        "https://docs.google.com/drawings/d/e/2PACX-1vSMiLqNWEXFs2Ba0zeitElSyI-IzZhhRXXM3FOkracE64BpUuhDKmv3Z_pu7JqEwWolCHJwFg34iB77/pub?w=686&h=422",
      subInterviewImage:
        "https://docs.google.com/drawings/d/e/2PACX-1vTLC-9kRj6RW-bnKNbabAnO--wEQaUkmSfBiBX50a5nXr-0ELMX4RaMGCmMpITOQzagml6I5mRo7v8G/pub?w=767&h=511",
      department: "開発設計部",
      content: "「絶対に絶対はない」からこそ、慎重な仕事を心掛けています",
      schedule: `
      06:00　起床・朝食<br>
      07:30　出社<br>
      08:10　朝礼<br>
      08:20　設計業務<br>
      12:00　昼食<br>
      12:45　設計業務<br>
      16:45　定時<br>
      17:00　残業開始<br>
      18:30　終業<br>
      19:30　帰宅<br>
      21:00　夕食・家事・お風呂<br>
      23:00　自由時間<br>
      24:00　就寝<br>
      `,
      careerPath: `
      2017年　入社　製造部に配属<br>
      2018年　開発設計部に異動<br>
      `,
      holiday: `趣味はたくさんあるのですが、バイクと車を整備することや、魚釣りやキャンプ、バーベキューなど基本的にアウトドアなことが好きです。<br>
      バイクは大学生の時に免許を取ってから１8台ほど乗りました。今もガレージに6台置いています。車は社会人になってから4台乗りました。休日に、自宅の車庫でバイクや車を分解して全塗装したり、部品がない場合は自分で溶接や切断して部品を製作したりしています。<br>
      魚釣りは、海で船釣りや筏釣りもしますが、季節になったら仕事終わりにエギングでイカを釣りに行きます。<br>
      `,
      short: `
      勉強から得られる知識はほんの一握りです。学生のうちにたくさんのことにチャレンジして、いろいろな人とふれあい、就職活動を行う際に自分が本当に就きたい職業を見つけられるように頑張ってください。
      `,
      interviews: [
        {
          title: "入社へのきっかけ、決め手は？",
          content:
            "大学時代に県外で一人暮らしをしており、就職するにあたって実家から近く大学時代に学んだことを生かせる職業に就きたいと考えていました。就職活動を始めるまで、地元にこのような会社があることを知らなかったのですが、木工機械や工作機械の設計は楽しそうだと思い入社しました。",
        },
        {
          title: "仕事のやりがいを感じるとき",
          content:
            "当社の製品は大半がオーダーメイドです。設計担当者は、ほぼ１人で１台の機械の設計を担います。責任は重大ですが、実際に組みあがった機械が自分の思い描いていた通りに動いたときにやりがいを感じます。",
        },
        {
          title: "日々、意識していること",
          content:
            "私の座右の銘は「絶対は絶対にない」です。設計を担当するにあたり、ミスなく機械が完成することは絶対にないと自分に言い聞かせて、細心の注意をはらうことを心掛けています。",
        },
        {
          title: "KIKUKAWAの良いところ",
          content: "有給休暇が取得しやすいところです。",
        },
        {
          title: "就職活動中の皆さんへ",
          content:
            "身近なところに自分の知らない分野の会社や、世界に誇れるモノづくりをしている会社がたくさんあります。大手企業ばかりに目を向けず、身近な会社も就職の選択肢に入れてみると、よりよい就職活動ができると思います。周りの人々の意見も大事ですが、最後に決めるのは自分自身です。自分で見て、聞いて「この会社で働きたい、この会社の一員になりたい」と思える会社に出会えるように頑張ってください。",
        },
      ],
    },
  },
  {
    image:
      "https://docs.google.com/drawings/d/e/2PACX-1vQIp3UqwA1OoKnj2EktSnUdusELss99tA0tn2WNcwV4bQ4soxlrCdoc0I8w5hf6cBQhMoNySvefUQ2n/pub?w=367&h=375",
    department: "開発設計部　機械設計",
    name: "Y.K.",
    ruby: "",
    career: "2018年入社<br>三重大学 工学部<br>電気電子工学科 卒業",
    interviewData: {
      interviewImage:
        "https://docs.google.com/drawings/d/e/2PACX-1vSbhyHxH3eQqqQ_2FFX9Fi1zuz5d0ut4lOTvVX_mJKAbp7xm028_R9kSPqaC0X2pjWBAN4NaEYqDyMu/pub?w=749&h=500",
      subInterviewImage:
        "https://docs.google.com/drawings/d/e/2PACX-1vSOS1I_wlaDTMFb4AVyrsmGMSfMs4g-LHO2_LWDrqzWOyMZtDj6KB_wjPnrt1YBOjCCqCGTXwzjPN-W/pub?w=741&h=492",
      department: "開発設計部",
      content: "トラブル対応もスムーズに行えるよう、知識をさらに深化させたい",
      schedule: `
      06:30　起床<br>
      07:30　出社<br>
      08:10　朝礼<br>
      08:20　設計業務、伝票処理<br>
      12:00　昼食<br>
      12:45　設計業務、伝票処理<br>
      16:45　定時<br>
      17:00　残業開始<br>
      18:30　終業<br>
      19:00　帰宅<br>
      23:30　就寝<br>
      `,
      careerPath: `
      2018年　入社　製造部に配属<br>
      2020年　開発設計部に異動<br>
      `,
      holiday: `
      スポーツ観戦、家でゆっくり過ごす、散歩
      `,
      interviews: [
        {
          title: "入社へのきっかけ、決め手は？",
          content:
            "電気科を卒業していますが、機械、電気に関わらず設計の仕事がしたいと考え、地元である伊勢市を中心に就職活動をしていました。当社では機械をまるごと1台設計しています。部品メーカーの設計よりも楽しそうだと思って入社を決めました。",
        },
        {
          title: "仕事のやりがいを感じるとき",
          content:
            "自分が設計した機械が問題なく組み立てられ、動いたときにやりがいを感じます。設計4年目の今年、はじめて新規の機械を担当させてもらいました。特に新規の機械ですと、お客様と一から打ち合わせをして仕様を満たせるように設計をします。組み立てはまだ先なので、問題なく機械が動いてお客様に満足していただけるかドキドキしています。",
        },
        {
          title: "日々、意識していること",
          content:
            "設計ではできる限りミスがないように、また長納期の部品の手配漏れは取り返しのつかないミスになり得るので何回もチェックを行っています。",
        },
        {
          title: "今後の目標",
          content:
            "設計の業務の中には機械のトラブル対応もあります。今はまだトラブル対応が得意でないので、機械の知識をもっと身につけて対応をスムーズに行えるようにしたいです。",
        },
        {
          title: "就職活動中の皆さんへ",
          content:
            "就職活動は不安なことが多く、周りの人の意見に流されることもあると思います。自分が何をしたいのか、どこで働きたいのかをよく考えて就職活動を頑張ってください。応援しています。",
        },
      ],
    },
  },
  {
    image:
      "https://docs.google.com/drawings/d/e/2PACX-1vRTBkKGPI1i5gJ3KOZ-DU1pFTxObHhWbeMYWQMNf8_y5YKJmMXVtsLer0-bDEA2CzM_YwmtTzI8YhVd/pub?w=375&h=364",
    department: "製造部　開発設計採用",
    name: "R.D.",
    ruby: "",
    career: "2023年入社<br>三重大学 生物資源学部<br>共生環境学科 卒業",
    interviewData: {
      interviewImage:
        "https://docs.google.com/drawings/d/e/2PACX-1vTda0xQp8hzQECfbvQkR37t9RarLf1aqOGIVqL-JVV23V75uXu8sKpXdBzh5oK1aQuk1G3vkKCLoDXP/pub?w=740&h=495",
      subInterviewImage:
        "https://docs.google.com/drawings/d/e/2PACX-1vRoe7Rd-Nf8c1Rr0alvkcF6FPrt7sTswarQHNr1gz0A7Q2cL2_EafxZ9ifHkfkYurFAH6_crVQkFV6L/pub?w=724&h=553",
      department: "製造部",
      content: "様々な仕事に積極的に取り組み、配属先の頼れる存在になりたい",
      schedule: `
      05:30　起床<br>
      07:30　出社<br>
      08:10　朝礼、工場内清掃<br>
      09:00　機械組み立て作業<br>
      10:00　小休止の後、作業再開<br>
      11:00　機械組み立て作業<br>
      12:00　昼食<br>
      12:45　機械組み立て作業<br>
      15:00　小休止の後、作業再開<br>
      16:00　機械組み立て作業<br>
      16:45　終業<br>
      18:00　帰宅、家事<br>
      19:00　晩酌、YouTube鑑賞<br>
      24:00　就寝<br>
      `,
      careerPath: `
      2023年　入社　製造部に配属
      `,
      holiday: `
      休日はドライブをして、県内外様々な場所を旅しています。
      `,
      interviews: [
        {
          title: "入社へのきっかけ、決め手は？",
          content:
            "機械設計の職を志す際に、製材・木工機械以外にも多様なNC機を展開しており、 とても興味を惹かれました。また、設計職に就く前に現場で数年研修させていただける所も大きな魅力でした。 その他にも工場の規模や設備の充実度、社員の皆さんの人柄の温かさや会社の雰囲気のよさも、大きな決め手となりました。",
        },
        {
          title: "仕事のやりがいを感じるとき",
          content:
            "ばらばらだった部品が組み合わり、徐々に機械として組み上がっていく過程にとてもやりがいを感じます。また、その過程の中で、自分に出来ることが増え、一つ一つの作業や機械への理解が深まっていくことを実感できることも仕事のやりがいに繋がっています。",
        },
        {
          title: "日々、意識していること",
          content:
            "分からない点や作業の中で不安に感じる点は、自己判断でうやむやにせず きちんと聞き 判断を仰ぐように心がけています。取引先からお金をいただいて機械をつくっているので、新入社員だからと甘んじることなく、 現場の一員として、自分のミスが機械の大きな欠陥になりかねないと常に意識して、万全の状態で機械が出荷できるように努めています。",
        },
        {
          title: "社員の矜持として",
          content:
            "弊社の魅力は何といっても、人と人との繋がりや人間の温かさだと思います。また、創業126年ということで、長年地域に貢献し地元の方々に親しまれ愛されてきた会社でもあります。これからも多くの方々に愛されるよう、そして今まで積み上げてきた信頼をさらに積み重ねられるよう、会社の一員として努めて参ります。",
        },
        {
          title: "今後の目標",
          content:
            "配属先で必要とされる人材になることです。今の現場期間を単なる設計職への研修期間と捉えるのではなく、現場の一員として様々な仕事に積極的に取り組み、必要とあれば資格の取得も視野に入れ、現場でスキルアップを目指します。また、設計と現場のギャップを埋められるよう、今後を見据えてベストな提案を模索したいです。",
        },
        {
          title: "就職活動中の皆さんへ",
          content:
            "就活をしていく中で、世間的にネームバリューのあるいわゆる大手企業を志す学生の方は非常に多いと思います。しかし、実際に働く中で重要なことは、このような大手企業に勤める社会的地位ではなく、実際の仕事内容や環境・人間関係の良好さだと身にしみて実感しています。 就職は人生の大きな選択です。だからこそ、その選択が企業の大きさやネームバリューに左右されないこと。 皆様に明るい新生活が訪れますよう、就活、頑張ってください。",
        },
      ],
    },
  },
  {
    image:
      "https://docs.google.com/drawings/d/e/2PACX-1vSJYnlthXingC7-uL_ztZTHOX8yiN6mKh4BOVosaxBq_JMx6MIr7enE8z1bjmrKhyG8AhQLzNzgtXrg/pub?w=349&h=379",
    department: "製造部　開発設計採用",
    name: "K.H.",
    ruby: "",
    career: "2023年入社<br>名城大学 理工学部<br>材料機能工学科 卒業",
    interviewData: {
      interviewImage:
        "https://docs.google.com/drawings/d/e/2PACX-1vShAakk-C67u-u9OrpHWiVuJVkxx7HzkPXmjgholzF200WZm2hYDLRkvM7Z8GWWxuESL-1K7Ztzl0bI/pub?w=591&h=417",
      subInterviewImage:
        "https://docs.google.com/drawings/d/e/2PACX-1vTR87xlR2R7yeD1FXiUsMSKPrPooWDzgw-zDbvqco7bWbsj_Y-i0Uw6dlGjLZCVoMZsA9_afZehgDqI/pub?w=655&h=552",
      department: "製造部",
      content: "経験や知識を豊富に身に着け、優れた設計者になることを目標に",
      schedule: `
      08:10　出社、朝礼<br>
      09:00　掃除<br>
      10:00　機械の組み立て<br>
      12:00　昼食<br>
      12:45　機械の組み立て<br>
      16:00　掃除<br>
      16:45　退勤<br>
      18:00　帰宅、お風呂<br>
      19:00　家族と夕食<br>
      20:00　読書や囲碁<br>
      21:00　テレビを見る<br>
      `,
      careerPath: `
      2023年　入社　製造部に配属
      `,
      holiday: `
      趣味は囲碁です。最近は対戦だけでなく観戦することが多く、棋士さんの棋譜を見るのが楽しみです。
      `,
      interviews: [
        {
          title: "入社へのきっかけ、決め手は？",
          content:
            "私はものづくりが好きで、大学の授業でCADなどを扱い設計に興味を持ちました。設計から生産までの一貫したものづくりで、そのなかでも製造現場で生産者と設計者が緊密にコミュニケーションを取れ、より良い製品をつくりだす会社でした。私も一員になり、より良い製品をつくりたいと思いました。",
        },
        {
          title: "仕事のやりがいを感じるとき",
          content:
            "私は今研修で機械の組み立ての様々な現場を回っています。図面を理解して、 部品を加工したり、あらゆることに対応し時間をかけて組み立てた機械ができると喜びを感じます。",
        },
        {
          title: "日々、意識していること",
          content:
            "機械の組み立てでは一つの部品を取り付けるだけでも様々な工程や工法があり、入社したばかりの私には知らない事ばかりです。そのため、一つ一つを大切に勉強するように意識しています。",
        },
        {
          title: "KIKUKAWAの良いところ",
          content:
            "当社はアットホームな雰囲気で、困っているとき助けてくれ、仕事を親切に教えてくれる上司や先輩方がたくさんいます。 私もこのような先輩になりたいと思います。",
        },
        {
          title: "今後の目標",
          content:
            "現場での経験や知識をたくさん身につけた設計者になり、 自分が設計した機械がお客様や生産者にも満足していただけたらいいなと思っています。",
        },
        {
          title: "就職活動中の皆さんへ",
          content:
            "就職活動は大変だと思います。けれど、自分と向きあい将来を考える期間だと思うので頑張ってください。自分が思う理想の将来になれることを願っています。",
        },
      ],
    },
  },
  {
    image: "image/nobori.jpg",
    department: "営業部",
    name: "T.N.",
    ruby: "",
    career: "2014年入社<br>法政大学 経営学部<br>経営戦略学科 卒業",
    interviewData: {
      interviewImage: "../image/nobori_interview.jpg",
      subInterviewImage: "",
      department: "営業部",
      content: "人生に無駄はない。失敗から学び、次につなげることが大切",
      schedule: `
      08:40　メールチェックおよび上司に報告<br>
      09:00　出張／営業活動<br>
      12:00　昼食　出張先の名産品を食べることも<br>
      13:00　引き続き営業活動<br>
      17:30　帰社　上司へ出張報告、報告書作成など<br>
      `,
      careerPath: `
      2014年　入社 営業部東京営業所に配属<br>
      2016年　貿易部に異動<br>
      2019年　営業部名古屋営業所に異動<br>
      `,
      holiday: `
      野球観戦、体育館を借りてのレクリエーション(バスケ、バレー、バドミントンなど)、ダーツ、飲み会などで、休日を過ごしています。
      `,
      interviews: [
        {
          title: "入社へのきっかけ、決め手は？",
          content:
            "私は三重県伊勢市の出身で、大学へ進学する際に卒業後は地元へ帰ってきたい思いが強く、当時の就職活動では地元企業を中心に活動をしておりました。その中で当社から内定をいただき、地元で有名かつ、上場していることもあり、この会社であれば両親も安心させられると思い入社を決断いたしました。",
        },
        {
          title: "仕事のやりがいを感じるとき",
          content:
            "私は文系出身で理系分野に疎いため、毎日が勉強で、覚えたことができるようになったことを体感できる点です。気軽に質問ができる上司や本社の技術スタッフがいるので、文系の私にもわかりやすく説明をしてくれるので助かっています。また、当社の製品は店頭に並んでいる家具、家電のような一般の方が目にするものではないので、目に見えてわかりやすく実感しにくい部分がありますが、今では当たり前となったスマホや旅行のときに使用する飛行機や新幹線などに当社が携わっている物を日常の中で多く見かけます。この仕事は陰ながら世の中を支えて、役に立っているんだと誇りに感じています。",
        },
        {
          title: "営業の仕事について",
          content:
            "事務所勤務の日は事務処理(見積書作成など)を行ったり、出張準備を行います。出張は週3～4日ほど。スケジュールは基本的に個人管理であるため、有休休暇の取得も含めて自由度は高いです。営業先は基本的に既存顧客への訪問ですが、新規開拓のために既存顧客以外も訪問することがあります。面談の時は機械の提案であったり、困りごと(生産など)の相談を聞くことが主です。",
        },
        {
          title: "日々、意識していること",
          content:
            "仕事は成功と失敗の２つしかないと思いますが、たとえ失敗になったとしてもそれは無駄な経験ではないと考えています。次に同じことを繰り返さないようどうすれば良いかを考え、失敗を失敗で終わらせないように心がけています。",
        },
        {
          title: "社員としての理想",
          content:
            "当社は国産で初めて製材・木工機械を製造した機械メーカーです。126年続いているのはその技術が今の現代式にうまく継承され続け、他社に追従させてこなかったことだと思います。長く続いているのには必ず理由があるのだと誇りに思いながら、仕事に励んでいます。",
        },
        {
          title: "今後の目標を教えてください",
          content:
            "まだまだ未熟であるため、社内外から一人前になったと言われる社会人になることです。",
        },
        {
          title: "就職活動中の皆さんへ",
          content:
            "新卒一括採用が根強い日本では、学生生活の短い期間で入社する会社を選ばなければなりません。正直、悩んでいるかと思いますが、いっぱい悩んでください。何気なく選ぶと後々に後悔することになります。後悔しないためにも、なぜその会社に行きたいのかを必死に考えて、自分自身が納得する希望の会社へ入社されることを願います。その中で当社へ入社を選んでくださるのであれば歓迎いたします。応援しています。就職活動をするにあたって、質問・相談があれば私のできる範囲で回答しますので、気軽にご連絡ください。",
        },
      ],
    },
  },
  {
    video: "https://www.youtube.com/embed/ApIudW67Hoc?si=J4Ed41Rvv6k1CnV1",
    image:
      "https://docs.google.com/drawings/d/e/2PACX-1vTKXc1hbxO5U7ro3Ejn1QohMJIUINfpl0KFg96f8W4Dk-68GYL2h7ATlDnrSVkQbAPgwdIaZr5pFVXB/pub?w=621&h=619",
    department: "貿易部",
    name: "T.K.",
    ruby: "",
    career: "2012年入社<br>名古屋外国語大学<br>外国語学部<br>英米語学科 卒業",
    interviewData: {
      interviewImage:
        "https://docs.google.com/drawings/d/e/2PACX-1vTKXc1hbxO5U7ro3Ejn1QohMJIUINfpl0KFg96f8W4Dk-68GYL2h7ATlDnrSVkQbAPgwdIaZr5pFVXB/pub?w=621&h=619",
      subInterviewImage:
        "https://docs.google.com/drawings/d/e/2PACX-1vQXeZwZeakftKnwuvd0QiUd7fKEXztKbNAAGyZEK62obWscXSjxJ2T14au9f6tmxCoWzBbGkxTO0efj/pub?w=746&h=497",
      department: "貿易部",
      content: "海外営業はコミュニケーション能力そして、忍耐強さがカギ",
      schedule: `
      07:30　出社 / 始業まで新聞等チェック<br>
      08:10　始業 / 朝礼 / メールチェック<br>
      09:00　アフターパーツ受注処理・社内手配<br>
      10:00　機械・部品の見積作業<br>
      11:00　各国代理店への対応・指示<br>
      12:00　昼食<br>
      12:45　技術部門との社内打合せ<br>
      14:00　メールチェック /機械・部品の見積作業<br>
      15:00　各国代理店と客先とのWeb会議<br>
      16:00　海外サプライヤーへの発注業務<br>
      16:45　終業<br>
      17:00　各国との時差により残業対応<br>
      18:30　退勤<br>
      `,
      careerPath: `
      2012年　入社　貿易部に配属<br>
      2022年　係長昇進<br>
      `,
      holiday: `
      平日の仕事終わりにスポーツジムで運動するようにしています。休日はサウナに行ったり、買い物に出かけたり、同僚とゴルフに行ったりしています。休みの夜には友人とお酒を飲みにも出かけますね。<br>
      隙間時間には、読書をするように心がけています。製造業に興味がある方は、エリヤフ・ゴールドラット著『The Goal』がお勧めです。本は厚いですが、小説なので読みやすいです。<br>
      `,
      short: `
      社会人になると学生時代に比べてプライベートの時間は少なくなり、仕事に追われがちです。ついつい休日も仕事のことが頭を離れないこともあります。これでは精神衛生上、好ましくありません。<br>
      ぜひ、日々の仕事を忘れて没頭できる趣味などを持つように心がけてください。また、仕事に関係のない人間関係を大切にしてください。なんでも気軽に話せる友人は宝物です。<br>
      `,
      interviews: [
        {
          title: "入社へのきっかけ、決め手は？",
          content:
            "学生時代の留学生との生活から「海外と繋がる職業」への希望を持ち、就職活動中に当社の貿易職を見つけました。入社面接での熱意を評価され、入社早々に海外出張のチャンスを得ることができました。当社は125年以上の歴史があり、東証上場の安定企業です。入社後の仕事の幅や成長機会も豊富なので、中途入社だった私にとっては、同年代に追いつくためにとても良い環境でした。",
        },
        {
          title: "仕事のやりがいを感じるとき",
          content:
            "古い歴史のある地方都市の一企業から、海外大手に機械を納入しています。ニュースでこれらの報道を見ると、私たちの技術が現在進行形の世界の経済活動と繋がっていることを実感し、とてもやりがいを感じます。特に多くのスマホのディスプレイ技術には当社の技術が使われており、これには非常に誇りを感じています。また、機密保持で公開できませんが、現在大々的に報道される注目の機械製品にも弊社の技術が大いに貢献しています。私個人としては、2022年2月には係長に昇進し、入社経路（中途採用など）も関係なく、公平な評価環境があることを実感しています。",
        },
        {
          title: "日々、意識していること",
          content:
            "忍耐とコミュニケーションです。一つのプロジェクト(機械）には多くの人が関わります。社内では20代の若手から60代のベテラン、さらには海外の様々な国の客先や代理店とも連携しています。異なる考え方や価値観がぶつかることも多いため、忍耐とコミュニケーション能力が鍵です。また、海外営業は華やかに見える一方、緻密な書類作成や交渉が必要です。心が折れそうになることもありますが、「神は細部に宿る」の言葉があるように、忍耐強く詳細な確認作業を行い、双方合意にもっていけるように努力をしています。",
        },
        {
          title: "KIKUKAWAの良いところは？",
          content:
            "有給休暇が比較的取りやすく、仕事とのメリハリをつけてプライベートも充実させやすいところが良いと思います。",
        },
        {
          title: "今後の目標を教えてください",
          content:
            "幾度となく不況や災害にも遭いながら、諸先輩方が途切れることなく後輩へバトンを繋いで現在の当社があります。諸先輩方がお客様や代理店様、協力メーカ様と築いてきた絆や信頼を壊すことなく、国内外での『KIKUKAWA』ブラントに磨きを掛けるように仕事をしていきたいと思います。また、自部署では中堅層になり、業務の幅も広がってきたことから管理職の視点を持つように心がけています。後輩や若手の指導も行うことで、上手くチーム(組織)として回るようにしていきたいと思います。また、語学・貿易・IT・会計の個人スキルを柱に、機械関係の専門知識を深めて上手く営業ができるようになることが直近の目標です。",
        },
        {
          title: "就職活動中の皆さんへ",
          content:
            "私は自分が何をやりたいのか、結論が出ないままでしたが、納得いくまで考えることにしました。当時は「第二新卒」という言葉もなく「新卒」か「中途」の窓口しかありませんでしたが、とりあえず多くの門を叩くことで道を開きました。 貿易のイメージは、通関作業をイメージしますが、実際は海外営業の割合が高く、イメージとのギャップがありました。業種や職種への固定概念があると思いますが、実際にどのような仕事なのか自分の目や耳で確かめてみると、異なった仕事像が見えてくることがあります。興味があれば、説明会などにも参加して直接従業員の方と話をしてみることが大切です。 イメージと違った面が見え、就職活動の考え方に広がりが出ると思います。 現在、皆さんも限られた時間や環境の中で、人生の岐路に立ち、大きな判断を迫られていると思いますが、その中で皆さんが出した答えに間違いはありません。ましてや、その経験こそが将来の自分を助けてくれると思います。 納得がいくまで考え抜いてください。応援しています。",
        },
      ],
    },
  },
];
