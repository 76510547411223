import { Box, Center, VStack, Text, Image } from "@chakra-ui/react";

import RenderTextWithBreaks from "../../utils/indention";

type Props = {
  title?: string;
  content?: string;
  image?: string;
};

const SubInterviewContent: React.FC<Props> = ({ title, content, image }) => {
  return (
    <Box mt={"32px"} mb={"32px"}>
      <Center>
        <VStack>
          <Box
            bg={"#1DC6FC"}
            p={2}
            pl={30}
            w={"90vw"}
            maxW={"512px"}
            borderRadius={5}
          >
            <Text color={"white"} fontWeight={700}>
              {title ?? "none"}
            </Text>
          </Box>
          <Box w={"80vw"} maxW={"480px"} pr={4}>
            <RenderTextWithBreaks
              inputStr={content ?? "none"}
              textColor={"#626262"}
              fontWeight={700}
              fontSize="md"
            />
          </Box>
          {image ? (
            <Image
              src={image}
              alt="image not found"
              mb={-3}
              w={"80vw"}
              maxW={"480px"}
            />
          ) : (
            <></>
          )}
        </VStack>
      </Center>
    </Box>
  );
};

export default SubInterviewContent;
