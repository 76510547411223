import { Box, Text, Center, VStack, Image } from "@chakra-ui/react";
import RenderTextWithBreaks from "../../utils/indention";

type Props = {
  title?: string;
  content?: string;
  image?: string;
  name?: string;
  department?: string;
  career?: string;
};

const InterviewTop: React.FC<Props> = ({
  title,
  content,
  image,
  name,
  department,
  career,
}) => {
  return (
    <>
      <Center>
        <VStack>
          <Box
            bg="#FE9821"
            w="90vw"
            maxW={"512px"}
            p={4}
            pl={8}
            color={"white"}
            borderRadius={5}
          >
            <Text fontSize={"xl"} fontWeight={700}>
              {title ?? "none"}
            </Text>
          </Box>
          <Box w="80vw" maxW={"480px"}>
            <Text fontSize={"xl"} fontWeight={700} color={"#FE9821"}>
              {content ?? "none"}
            </Text>
          </Box>

          <Box w={"90vw"} maxW={"480px"}>
            <Image
              src={
                image ??
                "https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
              }
              alt="image not found"
              mb={-3}
            />
            <Box mt={5}>
              <Box mb={5}>
                <Text fontWeight={700}>{name ?? "none"}</Text>
                <Text>{department ?? "none"}</Text>
              </Box>
              <RenderTextWithBreaks inputStr={career ?? "none"} fontSize="mb" />
            </Box>
          </Box>
        </VStack>
      </Center>
    </>
  );
};

export default InterviewTop;
